<template>
  <main>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Назад"
      @back="() => $router.go(-1)"
    />
    <a-form-model style="padding: 20px" layout="vertical" :model="lpu">
      <a-form-model-item label="Название">
        <a-input v-model="lpu.name" placeholder="Название ЛПУ" />
      </a-form-model-item>
      <a-form-model-item label="Адрес">
        <a-input
          v-model="lpu.address"
          placeholder="Краснодарский край, г. Краснодар, ул. Постовая 114"
        />
      </a-form-model-item>
      <a-form-model-item label="Телефоны">
        <a-input v-model="lpu.phones" placeholder="79181234567 79189837557" />
      </a-form-model-item>
      <a-form-model-item label="Расписание">
        <a-input v-model="lpu.schedule" placeholder="Пн-Пт 0700-2200" />
      </a-form-model-item>
      <a-form-model-item label="Latitude">
        <a-input v-model="lpu.latitude" placeholder="54.193122" />
      </a-form-model-item>
      <a-form-model-item label="Longitude">
        <a-input v-model="lpu.longitude" placeholder="54.193122" />
      </a-form-model-item>
      <a-form-model-item label="email">
        <a-input v-model="lpu.email" placeholder="email@yandex.ru" />
      </a-form-model-item>
      <a-form-model-item label="url">
        <a-input v-model="lpu.url" placeholder="checkskin.ru" />
      </a-form-model-item>
      <a-checkbox
        style="margin-bottom: 1rem"
        v-model="lpu.htmc_available"
        @change="onChange"
      >
        Доступная высокотехнологичная медицинская помощь (ВМП)
      </a-checkbox>

      <a-form-model-item>
        <a-button type="primary" @click="addLpu"> Добавить ЛПУ </a-button>
      </a-form-model-item>
    </a-form-model>
  </main>
</template>

<script>
import axios from "axios";

export default {
  name: "LpuAddMap",
  components: {},
  data() {
    return {
      lpu: {
        name: "",
        address: "",
        phones: "",
        schedule: "",
        latitude: "",
        longitude: "",
        htmc_available: "",
        email: "",
        url: "",
      },
      lpu_name: null,
      lpu_address: null,
      lpu_phones: null,
      lpu_schedule: null,
      lpu_lat: null,
      lpu_lon: null,
      lpu_htmc_available: false,
      lpu_email: null,
      lpu_url: null,
      errorMessage: null,
    };
  },
  methods: {
    onChange(e) {
      console.log(`checked = ${e.target.checked}`);
      this.lpu.htmc_available = e.target.checked;
    },
    addLpu() {
      console.log("addLpu called");
      let htmc = this.lpu.htmc_available ? true : false;
      let data = JSON.stringify({
        name: this.lpu.name,
        address: this.lpu.address,
        phones: this.lpu.phones,
        schedule: this.lpu.schedule,
        lat: this.lpu.latitude,
        lon: this.lpu.longitude,
        htmc_available: htmc,
        email: this.lpu.email,
        url: this.lpu.url,
      });
      axios
        .post("/cp/health-facilities/", data)
        .then((response) => {
          console.log("response from addLpu:", response);
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log("error from addLpu:", error);
          // @@@
          // this.errorMessage = error;
        });
    },
  },
};
</script>
